<template>
  <v-tooltip bottom max-width="200">
    <template v-slot:activator="{ on, attrs }">
      <div v-html="svg" v-bind="attrs" v-on="on"></div>
    </template>
    <span>
      Este Lead cuenta con broker externo.
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      svg: ` 
      <svg
        v-bind="attrs"
        v-on="on"
        width="40"
        height="40"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 434 444.4"
        xml:space="preserve"
      >
             <g>
          <path
            d="M337.6,85.1c5.6,0,10.2,4.6,10.2,10.2v237.3c0,5.6-4.6,10.2-10.2,10.2H100.3c-5.6,0-10.2-4.6-10.2-10.2V95.3
    c0-5.6,4.6-10.2,10.2-10.2H337.6 M337.6,72.3H100.3c-12.7,0-23.1,10.3-23.1,23v237.3c0,12.7,10.3,23.1,23.1,23.1h237.3
    c12.7,0,23.1-10.3,23.1-23.1V95.3C360.7,82.6,350.4,72.3,337.6,72.3L337.6,72.3z"
          />
        </g>
        <g>
          <path
            d="M152.3,109.9h29.6c9.7,0,17.2,1.9,22.5,5.6c5.2,3.8,7.9,9.1,7.9,16.1c0,3.8-0.9,7.2-2.7,10.1c-1.8,2.9-4.6,5.4-8.5,7.7v0.2
    c5.1,2.1,8.7,4.8,11,8.1c2.2,3.3,3.4,7.4,3.4,12.5c0,8.2-2.4,14.5-7.3,18.9c-4.9,4.4-11.8,6.6-20.7,6.6h-35.1L152.3,109.9
    L152.3,109.9z M182.9,143.6c2.9,0,5.1-0.9,6.8-2.6c1.7-1.8,2.5-4.1,2.5-7.1c0-5.9-3.9-8.8-11.7-8.8H172v18.5L182.9,143.6
    L182.9,143.6z M185.5,180.7c3.2,0,5.7-1.1,7.5-3.2c1.8-2.1,2.8-5,2.8-8.6c0-3.9-1.1-6.8-3.3-8.5c-2.2-1.8-5.8-2.6-10.8-2.6H172
    v22.9H185.5z"
          />
          <path
            d="M227.4,109.9h31.5c19.7,0,29.6,8.1,29.6,24.3c0,5.6-1.1,10.4-3.4,14.4s-5.5,7-9.8,9v0.2c1.5,1.1,2.6,2.1,3.4,3.1
    s1.5,2,2,3.2l12.5,31.8h-20.9l-9.6-25.6c-0.9-2.3-2.1-3.8-3.5-4.5s-4-1.1-7.7-1.1H247V196h-19.8v-86.1H227.4z M256.7,149
    c4,0,7.1-1,9.3-3.1c2.2-2.1,3.3-5.1,3.3-9c0-7.4-4.3-11.2-12.8-11.2h-9.3V149H256.7z"
          />
          <path
            d="M150.1,232.2h58.8v15.7h-39v17.3h34.9v15.7h-34.9v21.5h39v15.7h-58.8L150.1,232.2L150.1,232.2z"
          />
          <path
            d="M242.8,274.2l-25.8-42h22.2l15,25.9l15-25.9h22.2l-25.8,42l26.9,43.9h-21.8l-16.5-27.4l-16.3,27.4h-22L242.8,274.2z"
          />
        </g>
    </svg>
        `
    };
  },
  methods: {}
};
</script>
